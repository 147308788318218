import React from "react";
import { ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { Box, Card, Paper, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { Localization } from "../component_library/localization";
import { Image } from "../component_library/image";
import ContactForm from "../components/contact_form";
import { SchedulerWidget } from "../components/scheduler_widget";
import { MRTParagraphTitle, MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTParagraphBody } from "../components/wrappers";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={3}>
        <MRTTitle>{Localization.get("contact_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1.1} animated={false} src="images/contact_page_title_image.png"/>
          </Box>
        </Grow>
      </Row>
    </ContentPanel>
  );
}

function IntroSection() {
  const theme = useTheme();
  return (
    <ContentPanel color={theme.palette.background.paper}>

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("contact_page_form_title")}</ContentPanelTitle>

      <Row center = {false}>
        <Paragraph>
          <MRTParagraphTitle>{Localization.get("contact_page_form_user_support_title")}</MRTParagraphTitle>
          <MRTParagraphBody>{toHTML(Localization.get("contact_page_form_user_support_body"))}</MRTParagraphBody>
        </Paragraph>
      </Row>

      <Row center = {false}>
        <Paragraph>
          <MRTParagraphTitle>{Localization.get("contact_page_form_sales_signup_title")}</MRTParagraphTitle>
          <MRTParagraphBody>{toHTML(Localization.get("contact_page_form_schedule_body"))}</MRTParagraphBody>
        </Paragraph>
      </Row>

      <Row>
        <SchedulerWidget />
      </Row>

      <Row center = {false}>
        <Paragraph>
          <MRTParagraphBody>{toHTML(Localization.get("contact_page_form_signup_body"))}</MRTParagraphBody>
        </Paragraph>
      </Row>

    </ContentPanel>
  );
}

function FormSection() {
  const theme = useTheme();

  return (
    <ContentPanel backgroundImageSrc="images/background_bottom.png" padding={"3rem"}>

      <Row>
        <Paragraph>
          <MRTParagraphTitle>{Localization.get("contact_page_form_general_title")}</MRTParagraphTitle>
          <MRTParagraphBody>{toHTML(Localization.get("contact_page_form_general_body"))}</MRTParagraphBody>
        </Paragraph>
      </Row>

      <Row>
        <ContactForm />
      </Row>

      <Row></Row>
      <Row></Row>

    </ContentPanel>
  );
}

export function ContactPage() {

  return (
    <Box sx={{ backgroundImage: "url(images/background.png)" }}>
      <TitleSection />
      <IntroSection />
      <FormSection />
    </Box>
  );

}
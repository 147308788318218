import * as React from "react";
import {
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout.js";
import { Box, Card, Paper, useTheme } from "@mui/material";
import { Title } from "../component_library/title.js";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
  toHTML,
} from "../component_library/paragraph.js";
import { Localization } from "../component_library/localization.js";
import { Image } from "../component_library/image.js";
import { FreeTrialSection } from "../components/free_trial_section.js";
import { MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTParagraphBody, MRTParagraphTitle } from "../components/wrappers.js";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={3}>
        <MRTTitle>{Localization.get("about_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1.1} animated={false} src="images/about_page_title_image.png"/>
          </Box>
        </Grow>
      </Row>
    </ContentPanel >
  );
}

function StorySection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("about_page_story_title")}</ContentPanelTitle>
      <Paragraph>
        <MRTParagraphBody>{toHTML(Localization.get("about_page_story_body"))}</MRTParagraphBody>
      </Paragraph>
    </ContentPanel>
  );

}

function TeamSection() {
  const theme = useTheme();

  return (
    <>
      <ContentPanel color={"#00000000"}>
        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("about_page_team_title")}</ContentPanelTitle>
        <Paragraph>
          <MRTParagraphTitle>TBC...</MRTParagraphTitle>
        </Paragraph>
      </ContentPanel>
    </>
  );
}

export function AboutPage() {
  const theme = useTheme();

  return (
    <Box sx={{ backgroundImage: "url(images/background.png)" }}>
      <TitleSection />
      <StorySection />
      <FreeTrialSection />
    </Box>
  );
}

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


const fotonTheme = createTheme({
  palette: {
    contrastThreshold: 2,
    common: {
      black: '#11334f', 
      white: '#fff',
      transparent: 'rgba(0, 0, 0, 0)',
    },
    primary: {
      main: '#5580FF', // A blue color for primary elements
    },
    secondary: {
      main: '#FF8A73', // An orange color for secondary elements
    },
    error: {
      main: '#f44336', // A lighter red color for errors
    },
    background: {
      default: '#fff', // A light green background to contrast with black text
      paper: '#EEF1FD', // A lighter green background for "paper" components like Card and Paper
    },
    text: {
      primary: '#103852', // Dark gray text for high contrast
      secondary: '#757575', // Light gray text for low contrast
    },
  },
  typography: {
    fontSize:11,
    fontWeightRegular: 400,
    fontFamily: [
    'Poppins', 'sans-serif'
    ].join(','),
  },
  
});


ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={fotonTheme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
import React from "react";
import { InlineWidget   } from "react-calendly";
import { useTheme } from "@mui/material";
import { Localization } from "../component_library/localization";
export const SchedulerWidget = () => {
  const theme = useTheme();
  let backgroundColor = theme.palette.primary.main;
  let font = theme.typography.fontFamily;
  return (
      <InlineWidget 
      url={"https://calendly.com/myroyaltree/discover-my-royal-tree?locale=" + Localization.language + ""}
      styles={{minWidth: '100%', width: '100%', height: '50rem'}} 
      />
  );
};

import React, { useContext } from "react";
import {
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Button, useTheme } from "@mui/material";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
} from "../component_library/paragraph";
import { List, ListItem, ListItemIcon } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { StyleContext } from "../component_library/contexts";
import { Localization } from "../component_library/localization";
import { Footer } from "../component_library/footer";

export function FooterSection() {
  const theme = useTheme();
  const styleContext = useContext(StyleContext);
  const iconColor = theme.palette.common.white;

  return (
    <>
    <ContentPanel color={theme.palette.common.black} margin={0}>
      <Row sx={{ margin: 0, padding: 0 }}>
        <Paragraph>
          <ParagraphBody>
            <List>
              <ListItem>
                <ListItemIcon>
                  <EmailIcon sx={{ color: iconColor }} />
                </ListItemIcon>
                {Localization.get("mrt_email")}
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <LocationOnIcon sx={{ color: iconColor }} />
                </ListItemIcon>
                {Localization.get("mrt_address")}
              </ListItem>
            </List>
          </ParagraphBody>
        </Paragraph>
      </Row>
    </ContentPanel>
     <Footer color={theme.palette.common.black}>Copyright 2024</Footer>
     </>
  );
}

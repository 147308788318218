import React from "react";
import {
  Col,
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Image } from "../component_library/image";
import { Title } from "../component_library/title";
import { Localization } from "../component_library/localization";
import {
  Paragraph,
  ParagraphTitle,
  ParagraphBody,
  toHTML,
} from "../component_library/paragraph";
import { useTheme } from "@emotion/react";
import { Box, Stack } from "@mui/material";
import { FAQItem, MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTParagraphTitle, MRTParagraphBody } from "../components/wrappers";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();
  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={3}>
        <MRTTitle>{Localization.get("faq_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1.1} animated={false} src="images/faq_page_title_image.png" />
          </Box>
        </Grow>
      </Row>
    </ContentPanel>
  );
}


function ManagingAccountSection() {
  const theme = useTheme();
  const START_INDEX_SECTION_1 = 1;
  const START_INDEX_SECTION_2 = 3;
  const SECTION_1_LENGTH = 2;
  const SECTION_2_LENGTH = 4;

  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_users_managing_account_question")
  ).length;

  //TODO: separare loop su domande senza usare numeri in chiaro!!
  return (
    <ContentPanel color={theme.palette.background.paper}>

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_users_managing_account_title1")}</ContentPanelTitle>

      <>
        <Stack direction={"column"} spacing={2}>

          <Paragraph>
            <MRTParagraphTitle>{toHTML(Localization.get("faq_page_users_managing_account_title2"))}</MRTParagraphTitle>
          </Paragraph>

          {Array.from({ length: SECTION_1_LENGTH }, (_, i) => (
            <Paragraph>
              <FAQItem title={Localization.get(`faq_page_users_managing_account_question${i + START_INDEX_SECTION_1}`)}>
                <MRTParagraphBody>
                  {toHTML(Localization.get(`faq_page_users_managing_account_answer${i + START_INDEX_SECTION_1}`))}
                </MRTParagraphBody>
              </FAQItem>
            </Paragraph>
          ))}

          <Paragraph>
            <MRTParagraphTitle>{toHTML(Localization.get("faq_page_users_managing_account_title3"))}</MRTParagraphTitle>
          </Paragraph>

          {Array.from({ length: SECTION_2_LENGTH }, (_, i) => (
            <Paragraph>
              <FAQItem title={Localization.get(`faq_page_users_managing_account_question${i + START_INDEX_SECTION_2}`)}>
                <MRTParagraphBody>
                  {toHTML(Localization.get(`faq_page_users_managing_account_answer${i + START_INDEX_SECTION_2}`))}
                </MRTParagraphBody>
              </FAQItem>
            </Paragraph>
          ))}

        </Stack>
      </>
    </ContentPanel>
  );
}

function FunctionalitySection() {

  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_users_functionality_question")
  ).length;
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} >

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_users_functionality_title")}</ContentPanelTitle>

      <Stack direction={"column"} spacing={2} >
        {Array.from({ length: questionCount }, (_, i) => (
          <Paragraph>
            <FAQItem title={Localization.get(`faq_page_users_functionality_question${i + 1}`)}>
              <MRTParagraphBody>
                {toHTML(Localization.get(`faq_page_users_functionality_answer${i + 1}`))}
              </MRTParagraphBody>
            </FAQItem>
          </Paragraph>
        ))}
      </Stack>

    </ContentPanel>
  );
}

function MiscSection() {
  const theme = useTheme();
  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_users_misc_question")
  ).length;

  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc="images/background_bottom.png">

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_users_misc_title")}</ContentPanelTitle>

      <Stack direction={"column"} spacing={2}>
        {Array.from({ length: questionCount }, (_, i) => (
          <Paragraph>
            <FAQItem title={Localization.get(`faq_page_users_misc_question${i + 1}`)}>
              <MRTParagraphBody>
                {toHTML(Localization.get(`faq_page_users_misc_answer${i + 1}`))}
              </MRTParagraphBody>
            </FAQItem>
          </Paragraph>
        ))}
        <Row></Row>
        <Row></Row>
        <Row></Row>
      </Stack>
    </ContentPanel>
  );
}

export function FaqPageUsers() {
  return (
    <Box>
      <TitleSection />
      <ManagingAccountSection />
      <FunctionalitySection />
      <MiscSection />
    </Box>
  );
}

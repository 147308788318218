//create a component that contains an image and a paragraph
//if the orientation in horizontal the elements are put into a row and the image is on the left
//if the orientation is vertical the elements are put into a column and the image is on the top

import { Col, Row } from "../component_library/layout";
import { Image } from "../component_library/image";
import { Paragraph, ParagraphTitle, ParagraphBody } from "../component_library/paragraph";
import { useInView } from "react-intersection-observer";
import { MRTParagraphTitle, MRTParagraphBody } from "./wrappers";

export const ImageTextBlock = ({ orientation, image, title, body, center=true }) => {

    return (
        <>
            {orientation === "horizontal" ? (
                <Row spacing={2} weights={[4, 8]}>
                    <Image src={image} animated="false" />
                    <Col center={false}>
                        {title && <MRTParagraphTitle center={center}>{title}</MRTParagraphTitle>}
                        <MRTParagraphBody center={center}>{body}</MRTParagraphBody>
                    </Col>
                </Row>
            ) : (
                <Col spacing={2} weights={[4, 8]}>
                    <Image src={image} animated="false" />
                    <Paragraph>
                        {title && <MRTParagraphTitle center={center}>{title}</MRTParagraphTitle>}
                        <MRTParagraphBody center={center}>{body}</MRTParagraphBody>
                    </Paragraph>
                </Col>
            )}
        </>

    );
}
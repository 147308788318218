import React from "react";
import { ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { useTheme } from "@mui/material";
import { Paragraph, ParagraphBody, toHTML } from "../component_library/paragraph";
import { Localization } from "../component_library/localization";
import { Image } from "../component_library/image";
import { ButtonWrapper, MRTParagraphBody, CONTENT_PANEL_TITLE_FONT_WEIGHT } from "../components/wrappers";
import { RiceBowl } from "@mui/icons-material";

export function FreeTrialSection() {
  const theme = useTheme();
  return (
      <ContentPanel color={theme.palette.background.paper} backgroundImageSrc="images/background_bottom.png">
        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("free_trial_title")}</ContentPanelTitle>
        <Row weights={[9, 3]}>
          <Paragraph>
            <MRTParagraphBody> {toHTML(Localization.get("free_trial_body"))} </MRTParagraphBody>
          </Paragraph>
          <Image src="images/free_trial_section_image.png" />
        </Row>
        <Row centerContent={true}>
          <ButtonWrapper color="secondary" to="/free-trial">{Localization.get("free_trial_button")} </ButtonWrapper>
        </Row>
        <Row></Row>
      </ContentPanel>
  );
}
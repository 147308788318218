import React from "react";
import { ContentPanel, ContentPanelTitle, Row, Col } from "../component_library/layout";
import { Box, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import { Image } from "../component_library/image";
import { Localization } from "../component_library/localization";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { FreeTrialSection } from "../components/free_trial_section";
import { ImageTextBlock } from "../components/image_text_block";
import { MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTParagraphBody, MRTParagraphTitle, MRTImage } from "../components/wrappers";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={3}>
        <MRTTitle>{Localization.get("whymrt_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1.1} animated={false} src="images/whymrt_page_title_section_image.png" />
          </Box>
        </Grow>
      </Row>
    </ContentPanel>
  );
}

function IntroSection() {
  const theme = useTheme();
  return (
    <ContentPanel color={theme.palette.background.paper}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("homepage_section2_title")}
      </ContentPanelTitle>
      <Paragraph>
        <MRTParagraphBody>{toHTML(Localization.get("whymrt_page_intro_body"))}</MRTParagraphBody>
      </Paragraph>
    </ContentPanel>
  );
}

function Advantages1Section() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("whymrt_page_advantages_title")}</ContentPanelTitle>

      <Col>
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_1.png" body={toHTML(Localization.get("whymrt_page_advantages_management_body"))} />
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_3.png" body={toHTML(Localization.get("whymrt_page_advantages_collaborations_body"))} />
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_5.png" body={toHTML(Localization.get("whymrt_page_advantages_access_body"))} />
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_7.png" body={toHTML(Localization.get("whymrt_page_advantages_secure_body"))} />
      </Col>

      <Col>
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_2.png" body={toHTML(Localization.get("whymrt_page_advantages_time_body"))} />
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_4.png" body={toHTML(Localization.get("whymrt_page_advantages_errors_body"))} />
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_6.png" body={toHTML(Localization.get("whymrt_page_advantages_assistance_body"))} />
        <ImageTextBlock orientation="horizontal" image="images/whymrt_page_advantages1_image_8.png" body={toHTML(Localization.get("whymrt_page_advantages_developments_body"))} />
      </Col>

    </ContentPanel>
  );
}

function Advantages2Section() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"}>

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("whymrt_page_more_advantages_title")}</ContentPanelTitle>

      <ImageTextBlock orientation="vertical" image="images/whymrt_page_advantages2_image_1.png" body={toHTML(Localization.get("whymrt_page_more_advantages_customisable_body"))} />
      <ImageTextBlock orientation="vertical" image="images/whymrt_page_advantages2_image_2.png" body={toHTML(Localization.get("whymrt_page_more_advantages_languages_body"))} />
      <ImageTextBlock orientation="vertical" image="images/whymrt_page_advantages2_image_3.png" body={toHTML(Localization.get("whymrt_page_more_advantages_import_body"))} />

    </ContentPanel>
  );
}

function CatchSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("whymrt_page_catch_title")}</ContentPanelTitle>
      <Paragraph>
        <MRTParagraphBody>{toHTML(Localization.get("whymrt_page_catch_body"))}</MRTParagraphBody>
        <br />
        <MRTParagraphBody>{toHTML(Localization.get("whymrt_page_catch_paragraph1_body2"))}</MRTParagraphBody>
      </Paragraph>
    </ContentPanel>
  );
}

function FeaturesImageTextBlock({ image, title, body }) {
  return (
    <Col spacing={3} weights={[1, 3, 8]}>
      <MRTParagraphTitle>{title}</MRTParagraphTitle>
      <MRTImage src={image} />
      <MRTParagraphBody>{body}</MRTParagraphBody>
    </Col>
  );
}

function FeaturesSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"}>

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("whymrt_page_features_title")}</ContentPanelTitle>

      <FeaturesImageTextBlock image="images/private_author_area_screen.png" title={Localization.get("whymrt_page_features_author_title")} body={toHTML(Localization.get("whymrt_page_features_author_body"))} />
      <FeaturesImageTextBlock image="images/rights_tree_screen.png" title={Localization.get("whymrt_page_features_agent_title")} body={toHTML(Localization.get("whymrt_page_features_agent_body"))} />
      <FeaturesImageTextBlock image="images/automatic_reminders_screen.png" title={Localization.get("whymrt_page_features_publisher_title")} body={toHTML(Localization.get("whymrt_page_features_publisher_body"))} />

    </ContentPanel>
  );
}

export function WhyMrtPage() {
  return (
    <Box sx={{ backgroundImage: "url(images/background.png)" }}>
      <TitleSection />
      <IntroSection />
      <Advantages1Section />
      <Advantages2Section />
      <CatchSection />
      <FeaturesSection />
      <FreeTrialSection />
    </Box>
  );
}
import React from "react";
import { ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { Box, Button, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import { Paragraph, ParagraphBody, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { Localization } from "../component_library/localization";
import { Image } from "../component_library/image";
import FreeTrialForm from "../components/free_trial_form";
import { MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTParagraphTitle, MRTParagraphBody, ButtonWrapper } from "../components/wrappers";
import { MY_ROYAL_TREE_APP_SIGNUP_URL } from "../App";

function TitleSection() {
    const theme = useTheme();

    return (
        <ContentPanel color={"#00000000"}>
            <Row weights={[3, 6, 3]}>
                <Box />
                <MRTTitle>{Localization.get("free_trial_page_title")}</MRTTitle>
                <Image src="images/free_trial_section_image.png" />
            </Row>
        </ContentPanel>
    );
}

function FormSection() {
    const theme = useTheme();

    return (
        <ContentPanel color = {theme.palette.background.paper}  backgroundImageSrc="images/background_bottom.png">
            <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("free_trial_page_form_title")}</ContentPanelTitle>
            <Row>
                <Paragraph>
                    <MRTParagraphTitle center={true}>{Localization.get("free_trial_page_form_subtitle")}</MRTParagraphTitle>
                    <br></br>
                    <MRTParagraphBody center={true}>{toHTML(Localization.get("free_trial_page_form_body"))}</MRTParagraphBody>
                </Paragraph>
            </Row>
            <Row>
            <ButtonWrapper color="secondary" to={MY_ROYAL_TREE_APP_SIGNUP_URL}>{Localization.get("free_trial_page_form_send_button")} </ButtonWrapper>
            </Row>
            <Row />
        </ContentPanel>
    );
}

export function FreeTrialPage() {
    return (
        <>
            <TitleSection />
            <FormSection />
        </>
    );
}
//component that wrap mui button and receives color and to as props

import React, { useRef } from "react";
import { Box, Button } from "@mui/material";
import { NavigationLink } from "../component_library/navigation";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Image } from "../component_library/image";
import { useTheme } from "@mui/material";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ParagraphTitle, ParagraphBody } from "../component_library/paragraph";
import { Title } from "../component_library/title";
import { useState, useEffect } from "react";

export const ButtonWrapper = ({ color, to, children }) => {
  const theme = useTheme();
  const buttonColor = theme.palette[color].main;

  return (
      <Button
        variant="contained"
        color={color}
        sx={{
          borderRadius: "2rem",
          py: "1rem",
          px: "2rem",
          boxShadow: "none",
          fontWeight: "500",
          fontSize: "12px",
          ":hover": {
            boxShadow: `0 0 10px ${buttonColor}`, // Change the color as needed
            backgroundColor: buttonColor,
          },
        }}
      >
        <NavigationLink to={to}>{children}</NavigationLink>
      </Button>
  );
};

export const FeatureListItem = ({ children, iconColor }) => {
  const theme = useTheme();

  const checkIcons = {
    primary: "images/check_primary.png",
    primary_light: "images/check_primary_light.png",
    secondary: "images/check_secondary.png",
  }

  const textSize = theme.typography.fontSize * 1.4;
  const textColor = theme.palette.text.secondary;

  return (
    <ListItem disablePadding>
      <ListItemIcon sx={{ minWidth: "2.5rem" }}>
        <Image src={checkIcons[iconColor]} />
      </ListItemIcon>
      <ListItemText primary={children} primaryTypographyProps={{fontSize: textSize, color: textColor}}/>
    </ListItem>
  );
};

export const FAQItem = ({ title, children }) => {
  const theme = useTheme();
  return (
    <Accordion sx={{ backgroundColor: theme.palette.background.default }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <MRTParagraphTitle>{title}</MRTParagraphTitle>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
}


export const MRTTitle = ({ color, variant = "h1", children, center=true}) => {
  return (
    <Title color={color} variant={variant} fontWeight="400" center={center}>{children}</Title>
  );
}

export const MRTParagraphTitle = ({ center, children, sx }) => {
  const theme = useTheme();
  return (
    <ParagraphTitle color={theme.palette.text.primary} center={center} fontWeight="400" sx={sx}>{children}</ParagraphTitle>
  );
}

export const MRTParagraphBody = ({ center, children }) => {
  const theme = useTheme();
  return (
    <ParagraphBody color={theme.palette.text.secondary} center={center}>{children}</ParagraphBody>
  );
}

export const CONTENT_PANEL_TITLE_FONT_WEIGHT = "400";



export const MRTImage = ({ scale, src }) => {
  const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

  return (
    <Box sx={{
      transform: isHovered ? "scale(1.1)" : "scale(1)",
      transition: "transform 0.2s ease-out",
    }}
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    >
      <Image scale={scale} src={src} sx={{
        boxShadow: `0 0 2rem rgba(116, 156, 230, 0.5)`,
        borderRadius: "1rem",
      }} />
    </Box>

  );
}
import React, { useState, useEffect } from "react";
import { TextField, Button, Container, Typography, Snackbar, SnackbarContent } from "@mui/material";
import { useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { EMAILJS_CONFIG, sendEmail } from "../network/emailjs_api";
import { Localization } from "../component_library/localization";
import { Text } from "../component_library/text";

EMAILJS_CONFIG.serviceId = "service_7jtsi1a";
EMAILJS_CONFIG.templateId = "contact_template";
EMAILJS_CONFIG.publicKey = "-pN7LrWDE5Pr3-EKC";


const ContactForm = ({ children }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [company, setCompany] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarColor, setSnackbarColor] = useState("success");
    const [emailSent, setEmailSent] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const params = { 
          name: name,
          from: email,
          //to:"danieleaurigemma@libero.it",
          to: "lorenzo@bookonatree.com",
          message: message,
          subject: "MyRoyalTree Information Request",
        };
    
        try {
          await sendEmail(params);
          setSnackbarMessage(Localization.get("contact_page_form_success_message"));
          setSnackbarColor("success");
          setEmailSent(true);
          localStorage.setItem('emailSent', 'true');
        } catch (error) {
          setSnackbarMessage(Localization.get("contact_page_form_error_message"));
          setSnackbarColor("error");
        }
    
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const containerStyle = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        backgroundColor: "#ffffff",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
        backgroundSize: "100% 100%",
        borderRadius: "20px",
        paddingTop: "20px",
    };

    const formStyle = {
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        padding: "16px",
        backgroundColor: "rgba(0, 0, 0, 0)",
        borderRadius: "10px",
        width: isSmallScreen ? "100%" : "100%",
        alignItems: "center",
    };

    const textFieldStyle = {
        border: "none",
        borderRadius: "10px",
        width: "100%",
        borderColor: "transparent",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        "& .MuiInputBase-root": {
            background: "none",
            "&:hover": {
                background: "none",
            },
            "&:before": {
                borderBottom: "none",
            },
        },
    };

    const buttonStyle = {
        whiteSpace: "nowrap",
        borderRadius: "30px",
        width: "fit-content",
        alignSelf: "center",
        padding: "15px 60px",
        marginTop: "50px",
    };

    return (
        <Container style={containerStyle}>
            {emailSent ? ( // TODO: remove false, this is just for testing
                <Text
                    variant="h4"
                    center
                    sx = {{ mt: "50px", mb: "60px"}}
                >
                    {Localization.get("email_sent_message")}
                </Text>
            ) : (
                <form style={formStyle} onSubmit={handleSubmit}>
                    {children && <Typography
                        variant="h3"
                        align="center"
                        gutterBottom
                        color={theme.palette.text.primary}
                        sx={{ mb: "50px" }}
                    >
                        {children}
                    </Typography>
                    }
                    <TextField
                        label={Localization.get("contact_page_form_name_label")}
                        variant="filled"
                        sx={textFieldStyle}
                        color="secondary"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <TextField
                        label={Localization.get("contact_page_form_company_label")}
                        variant="filled"
                        sx={textFieldStyle}
                        color="secondary"
                        required
                        name="company"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                    />
                    <TextField
                        label={Localization.get("contact_page_form_email_label")}
                        variant="filled"
                        sx={textFieldStyle}
                        color="secondary"
                        required
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        label={Localization.get("contact_page_form_phone_number_label")}
                        variant="filled"
                        sx={textFieldStyle}
                        color="secondary"
                        required
                        name="phoneNumber"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <TextField
                        label={Localization.get("contact_page_form_message_label")}
                        variant="filled"
                        multiline
                        rows={4}
                        sx={textFieldStyle}
                        color="secondary"
                        name="message"
                        required
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        style={buttonStyle}
                        color={"secondary"}
                    >
                        {Localization.get("contact_page_form_send_button")}
                    </Button>
                </form>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <SnackbarContent
                    message={snackbarMessage}
                    style={{ backgroundColor: theme.palette[snackbarColor].main }}
                />
            </Snackbar>
        </Container>
    );
};

export default ContactForm;
import React from "react";
import { Container, useMediaQuery, Grow } from "@mui/material";
import Box from "@mui/material/Box";

/** Generic ContentContainer */
export function Image({ src, sx, scale = 1, rotation = 0, animated = true }) {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  // if the screen is small, the image width is the screen width, else it is the container width
  return (
    <>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            transform: `rotate(${rotation}deg)`, // Apply rotation here
          }}
        >
          {animated && (
            <Grow in={true} timeout={1000}>
              <Box
                component="img"
                src={src}
                sx={{
                  ...sx,
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transform: `scale(${scale})`, // Apply rotation here
                }}
              />
            </Grow>
          )}
          {!animated && (
            <Box>
              <Box
                component="img"
                src={src}
                sx={{
                  ...sx,
                  objectFit: "contain",
                  maxWidth: "100%",
                  maxHeight: "100%",
                  transform: `scale(${scale})`, // Apply rotation here
                }}
              />
            </Box>
          )}
        </Box>
    </>
  );
}

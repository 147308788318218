import emailjs from "@emailjs/browser";

export const EMAILJS_CONFIG = {
  serviceId: "null",
  templateId: "null",
  publicKey: "null",
};

export async function sendEmail(
  params = { name: "", from: "", subject: "", message: "", to: "" }
) {
        //await sendEmailPHP(params);
        await sendEmailEmailJS(params);
   
}

async function sendEmailEmailJS(params) {
    await emailjs.send(EMAILJS_CONFIG.serviceId, EMAILJS_CONFIG.templateId, params, {
        publicKey: EMAILJS_CONFIG.publicKey,
    });
}


async function sendEmailPHP(params){
    const response = await fetch("send_email.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
      });
      const status = response.status;
      const errorMessage = response.headers.get("X-PHP-Error");
      if (status !== 200) {
        throw new Error("Failed to send email", status, errorMessage);
      }else{
          let text = await response.text();
      }
}

export default sendEmail;

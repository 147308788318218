import React from "react";
import {
  Col,
  ContentPanel,
  ContentPanelTitle,
  Row,
} from "../component_library/layout";
import { Box, Stack, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import { Image } from "../component_library/image";
import { Localization } from "../component_library/localization";
import {
  Paragraph,
  ParagraphTitle,
  toHTML,
} from "../component_library/paragraph";
import { ParagraphBody } from "../component_library/paragraph";
import { MRTCardContainer } from "../components/foton_card";
import { List, ListItem } from "@mui/material";
import { ButtonWrapper, MRTParagraphBody, MRTParagraphTitle, MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT } from "../components/wrappers";
import { FeatureListItem } from "../components/wrappers";
import { Text } from "../component_library/text";
import { MY_ROYAL_TREE_APP_SIGNUP_URL } from "../App";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={3}>
        <MRTTitle>{Localization.get("pricing_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1.1} animated={false} src="images/pricing_page_title_image.png" />
          </Box>
        </Grow>
      </Row>
    </ContentPanel>
  );
}

const IntroCard = ({ children }) => {
  const theme = useTheme();
  return (
    <MRTCardContainer color={theme.palette.background.paper}>
      <Col spacing={2} weights={[6, 3, 3]}>
        {children}
      </Col>
    </MRTCardContainer>
  );
}

const PricingPlanCard = ({ children }) => {
  const theme = useTheme();
  return (
    <MRTCardContainer ghost={false} color={theme.palette.background.default}>
      <Col spacing={2} weights={[2, 2, 6, 2]}>
        {children}
      </Col>
    </MRTCardContainer>
  );
}


function MRTPrice({ children }) {
  return (
    <Text variant="h4" fontWeight="bold" center={true}>
      {children}
    </Text>
  );
}

function IntroSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper}>

      <Row>
        <Paragraph>
          <MRTParagraphBody>
            {toHTML(Localization.get("pricing_page_intro_intro_body"))}
          </MRTParagraphBody>
        </Paragraph>
      </Row>

      <Row>
        <IntroCard>
          <Image src="images/pricing_page_intro_image_1.png" />
          <Paragraph>
            <MRTParagraphTitle center>{Localization.get("pricing_page_intro_author_title")}</MRTParagraphTitle>
          </Paragraph>
          <ButtonWrapper to="#author" color="secondary">
            {Localization.get("pricing_page_intro_button")}
          </ButtonWrapper>
        </IntroCard>
        <IntroCard>
          <Image src="images/pricing_page_intro_image_2.png" />
          <Paragraph>
            <MRTParagraphTitle center>{Localization.get("pricing_page_intro_agent_title")}</MRTParagraphTitle>
          </Paragraph>
          <ButtonWrapper to="#agent" color="secondary">
            {Localization.get("pricing_page_intro_button")}
          </ButtonWrapper>
        </IntroCard>
        <IntroCard>
          <Image src="images/pricing_page_intro_image_3.png" />
          <Paragraph>
            <MRTParagraphTitle center>{Localization.get("pricing_page_intro_publisher_title")}</MRTParagraphTitle>
          </Paragraph>
          <ButtonWrapper to="#publisher" color="secondary">
            {Localization.get("pricing_page_intro_button")}
          </ButtonWrapper>
        </IntroCard>
      </Row>

    </ContentPanel>
  );
}

function AuthorSection() {
  const theme = useTheme();
  const cardTitleVariant = "h4";

  return (
    <div id="author">
      <ContentPanel color={"#00000000"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("pricing_page_authors_title")}
        </ContentPanelTitle>



        <Row weights={[2, 8, 2]}>
          <Box />
          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_authors_standard_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_authors_standard_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_authors_standard_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_authors_standard_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            <ButtonWrapper to={MY_ROYAL_TREE_APP_SIGNUP_URL} color="secondary">
              {Localization.get("pricing_page_card_button")}
            </ButtonWrapper>
          </PricingPlanCard>
          <Box />
        </Row>

      </ContentPanel>

    </div>
  );
}

function AgentSection() {
  const theme = useTheme();
  const cardTitleVariant = "h4";
  var cardButton = <ButtonWrapper to={MY_ROYAL_TREE_APP_SIGNUP_URL} color="secondary">
    {Localization.get("pricing_page_card_button")}
  </ButtonWrapper>

  return (
    <div id="agent">
      <ContentPanel color={"#00000000"} padding={"2rem"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("pricing_page_agent_title")}
        </ContentPanelTitle>

        <Row weights={[4, 4, 4]}>
          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_agent_bodoni_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_agent_bodoni_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_agent_bodoni_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_agent_bodoni_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_agent_garamond_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_agent_garamond_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_agent_garamond_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_agent_garamond_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_agent_gill_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_agent_gill_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_agent_gill_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_agent_gill_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

        </Row>
        <Row weights={[4, 4, 4]}>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_agent_pickering_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_agent_pickering_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_agent_pickering_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_agent_pickering_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_agent_simoncini_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_agent_simoncini_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_agent_simoncini_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_agent_simoncini_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>
        </Row>
      </ContentPanel>
    </div>
  );
}

function PublisherSection() {
  const theme = useTheme();
  const cardTitleVariant = "h4";
  var cardButton = <ButtonWrapper to={MY_ROYAL_TREE_APP_SIGNUP_URL} color="secondary">
    {Localization.get("pricing_page_card_button")}
  </ButtonWrapper>

  return (
    <div id="publisher">
      <ContentPanel color={"#00000000"} padding={"3rem"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("pricing_page_publisher_title")}
        </ContentPanelTitle>

        <Row weights={[4, 4, 4]}>
          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_publisher_bodoni_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_publisher_bodoni_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_publisher_bodoni_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_publisher_bodoni_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_publisher_baskerville_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_publisher_baskerville_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_publisher_baskerville_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_publisher_baskerville_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_publisher_garamond_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_publisher_garamond_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_publisher_garamond_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_publisher_garamond_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

        </Row>
        <Row weights={[4, 4, 4]}>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_publisher_gill_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_publisher_gill_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_publisher_gill_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_publisher_gill_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_publisher_pickering_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_publisher_pickering_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_publisher_pickering_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_publisher_pickering_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>

          <PricingPlanCard>
            <MRTTitle variant={cardTitleVariant} center>{toHTML(Localization.get("pricing_page_publisher_simoncini_title"))}</MRTTitle>
            <MRTPrice>{Localization.get("pricing_page_publisher_simoncini_price")}</MRTPrice>
            <List>
              {Array(Object.keys(Localization.strings).filter(key =>
                key.includes("pricing_page_publisher_simoncini_feature")
              ).length).fill().map((_, i) => <FeatureListItem iconColor="primary_light">{Localization.get(`pricing_page_publisher_simoncini_feature${i + 1}`)}</FeatureListItem>)}
            </List>
            {cardButton}
          </PricingPlanCard>
        </Row>

      </ContentPanel>
    </div>
  );
}

function ContactSection() {
  const theme = useTheme();
  return (
    <ContentPanel color={"#00000000"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("pricing_page_contact_title")}
      </ContentPanelTitle>
      <Stack direction={"column"} spacing={"0rem"}>
        <Paragraph>
          <MRTParagraphBody center>
            {Localization.get("pricing_page_contact_body")}
          </MRTParagraphBody>
        </Paragraph>

        <Row>
          <ButtonWrapper to="/contact" color="primary">

            {Localization.get("pricing_page_contact_button")}
          </ButtonWrapper>
        </Row>
      </Stack>
    </ContentPanel>
  );
}

export function FreeTrialSection() {
  const theme = useTheme();
  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc="images/background_bottom.png">
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("pricing_page_free_trial_title")}
      </ContentPanelTitle>
      <Row weights={[9, 3]}>
        <Col spacing={4}>
          <Paragraph>
            <MRTParagraphBody>
              {toHTML(Localization.get("pricing_page_free_trial_body"))}
            </MRTParagraphBody>
          </Paragraph>
          <ButtonWrapper to="/free-trial" color="secondary">
            {Localization.get("free_trial_button")}
          </ButtonWrapper>
        </Col>
        <Image src="images/free_trial_section_image.png" />
      </Row>
      <Row></Row>
    </ContentPanel>
  );
}

export function PricingPage() {
  return (
    <Box sx={{ backgroundImage: "url(images/background.png)" }}>
      <TitleSection />
      <IntroSection />
      <AuthorSection />
      <AgentSection />
      <PublisherSection />
      <ContactSection />
      <FreeTrialSection />
    </Box>
  );
}

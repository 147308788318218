import React from "react";
import { Container, useMediaQuery, Fade, Grow, Slide } from "@mui/material";
import { Text } from "./text";



export const Title = React.forwardRef(function (props, ref){
    let { color, variant="h1", margin="3", fontWeight="bold", center=true, children } = props;
    return (
            <Text {...props} ref={ref} variant={variant} fontWeight={fontWeight} color={color} margin={margin} center={center}>
                {children}
            </Text>
    );
}
);
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const ChatWidget = () =>  {
    return (
        <div>
            <TawkMessengerReact
                propertyId="65c11f4f8d261e1b5f5c9da5"
                widgetId="1hlt64k1e"/>
        </div>
    );
}
import React from "react";
import {
  ContentPanel,
  ContentPanelTitle,
  Row,
  Col
} from "../component_library/layout";
import { Box, Stack, useTheme } from "@mui/material";
import { Title } from "../component_library/title";
import { Image } from "../component_library/image";
import { Localization } from "../component_library/localization";
import { Paragraph, ParagraphTitle, toHTML } from "../component_library/paragraph";
import { ParagraphBody } from "../component_library/paragraph";
import { FreeTrialSection } from "../components/free_trial_section";
import { Link } from "react-router-dom";
import { ImageTextBlock } from "../components/image_text_block";
import { MRTCardContainer } from "../components/foton_card";
import { Text } from "../component_library/text";
import { MRTParagraphBody, MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTImage } from "../components/wrappers";
import { useMediaQuery } from "@mui/material";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={4}>
        <MRTTitle>{Localization.get("features_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
        <Image scale={1.1} animated={false} src="images/features_page_title_section_image.png"/>
        </Box>
        </Grow>
      </Row>
    </ContentPanel>
  );
}

export const IntroButton = ({ to, children }) => {
  const theme = useTheme();
  return (
    <Link to={to}>
      <Col spacing={1} weights={[8, 4]}>
        {children}
      </Col>
    </Link>
  );

};

function IntroSection() {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <ContentPanel color={theme.palette.background.paper}>

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("features_page_intro_title")}</ContentPanelTitle>
      <Row>
        <Paragraph>
          <MRTParagraphBody>
            {toHTML(Localization.get("features_page_intro_body"))}
          </MRTParagraphBody>
        </Paragraph>
      </Row>

      <Row>
        <Stack direction={isSmallScreen?"column": "row"} spacing={2} justifyContent="center" alignItems="center">

          <IntroButton to="#acquisition">
            <Image src="images/features_page_intro_image_1.png" />
            <MRTParagraphBody center>{Localization.get("features_page_intro_acquisition_button")}</MRTParagraphBody>
          </IntroButton>

          <Box sx={{ width: "3rem" }}>
          <Image src="images/arrow.png" rotation={isSmallScreen?90:0} />
          </Box>

          <IntroButton to="#sale">
            <Image src="images/features_page_intro_image_2.png" />
            <MRTParagraphBody center>{Localization.get("features_page_intro_sale_button")}</MRTParagraphBody>
          </IntroButton>

          <Box sx={{ width: "3rem" }}>
          <Image src="images/arrow.png" rotation={isSmallScreen?90:0}/>
          </Box>

          <IntroButton to="#statements">
            <Image src="images/features_page_intro_image_3.png" />
            <MRTParagraphBody center>{Localization.get("features_page_intro_statements_button")}</MRTParagraphBody>
          </IntroButton>

          <Box sx={{ width: "3rem" }}>
          <Image src="images/arrow.png" rotation={isSmallScreen?90:0}/>
          </Box>

          <IntroButton to="#reporting">
            <Image src="images/features_page_intro_image_4.png" />
            <MRTParagraphBody center>{Localization.get("features_page_intro_reporting_button")}</MRTParagraphBody>
          </IntroButton>

          <Box sx={{ width: "3rem" }}>
          <Image src="images/plus.png" rotation={isSmallScreen?90:0}/>
          </Box>

          <IntroButton to="#management">
            <Image src="images/features_page_intro_image_5.png" />
            <MRTParagraphBody center>{Localization.get("features_page_intro_management_button")}</MRTParagraphBody>
          </IntroButton>

        </Stack>
      </Row>
    </ContentPanel>
  );
}

function AcquisitionSection() {
  const theme = useTheme();

  return (
    <div id="acquisition">
      <ContentPanel color={"#00000000"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("features_page_acquisition_title")}
        </ContentPanelTitle>

        <Row>
        <ImageTextBlock orientation="vertical" image="images/features_page_acquisition_section_image_1.png" title={Localization.get("features_page_acquisition_network_title")} body={Localization.get("features_page_acquisition_network_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_acquisition_section_image_2.png" title={Localization.get("features_page_acquisition_titles_title")} body={Localization.get("features_page_acquisition_titles_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_acquisition_section_image_3.png" title={Localization.get("features_page_acquisition_offers_title")} body={Localization.get("features_page_acquisition_offers_body")} />
        </Row>
      </ContentPanel>
    </div>
  );
}

function SaleSection() {
  const theme = useTheme();

  return (
    <div id="sale">
      <ContentPanel color={"#00000000"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("features_page_sale_title")}
        </ContentPanelTitle>

        <Row>
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_1.png" title={Localization.get("features_page_sale_submissions_title")} body={Localization.get("features_page_sale_submissions_body")} />
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_2.png" title={Localization.get("features_page_sale_editions_title")} body={Localization.get("features_page_sale_editions_body")} />
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_3.png" title={Localization.get("features_page_sale_rights_title")} body={Localization.get("features_page_sale_rights_body")} />
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_4.png" title={Localization.get("features_page_sale_transfers_title")} body={Localization.get("features_page_sale_transfers_body")} />
        </Row>

        <Row>
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_5.png" title={Localization.get("features_page_sale_budgets_title")} body={Localization.get("features_page_sale_budgets_body")} />
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_6.png" title={Localization.get("features_page_sale_currencies_title")} body={Localization.get("features_page_sale_currencies_body")} />
          <ImageTextBlock orientation="vertical" image="images/features_page_sale_section_image_7.png" title={Localization.get("features_page_sale_warehouses_title")} body={Localization.get("features_page_sale_warehouses_body")} />
        </Row>

      </ContentPanel>
    </div>
  );
}

function StatementsSection() {
  const theme = useTheme();

  return (
    <div id="statements">
      <ContentPanel color={"#00000000"}>
        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("features_page_statements_title")}
        </ContentPanelTitle>
        <ImageTextBlock orientation="vertical" image="images/features_page_statements_section_image_1.png" title={Localization.get("features_page_statements_in_title")} body={Localization.get("features_page_statements_in_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_statements_section_image_2.png" title={Localization.get("features_page_statements_out_title")} body={Localization.get("features_page_statements_out_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_statements_section_image_3.png" title={Localization.get("features_page_statements_contracts_title")} body={Localization.get("features_page_statements_contracts_body")} />
      </ContentPanel>
    </div>
  );
}

function ReportingSection() {
  const theme = useTheme();

  return (
    <div id="reporting">
      <ContentPanel color={"#00000000"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("features_page_reporting_title")}
        </ContentPanelTitle>

        <ImageTextBlock orientation="vertical" image="images/features_page_reporting_section_image_1.png" title={Localization.get("features_page_reporting_overview_title")} body={Localization.get("features_page_reporting_overview_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_reporting_section_image_2.png" title={Localization.get("features_page_reporting_sales_map_title")} body={Localization.get("features_page_reporting_sales_map_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_reporting_section_image_3.png" title={Localization.get("features_page_reporting_author_area_title")} body={Localization.get("features_page_reporting_author_area_body")} />

      </ContentPanel>
    </div>
  );
}

function ManagementSection() {
  const theme = useTheme();

  return (
    <div id="management">
      <ContentPanel color={"#00000000"}>

        <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
          {Localization.get("features_page_management_title")}
        </ContentPanelTitle>

        <ImageTextBlock orientation="vertical" image="images/features_page_management_section_image_1.png" title={Localization.get("features_page_management_dashboard_title")} body={Localization.get("features_page_management_dashboard_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_management_section_image_2.png" title={Localization.get("features_page_management_payments_title")} body={Localization.get("features_page_management_payments_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_management_section_image_3.png" title={Localization.get("features_page_management_reminders_title")} body={Localization.get("features_page_management_reminders_body")} />
        <ImageTextBlock orientation="vertical" image="images/features_page_management_section_image_4.png" title={Localization.get("features_page_management_importing_title")} body={Localization.get("features_page_management_importing_body")} />

      </ContentPanel>
    </div>
  );
}

export function FeaturesPage() {
  return (
    <Box sx={{ backgroundImage: "url(images/background.png)" }}>
      <TitleSection />
      <IntroSection />
      <AcquisitionSection />
      <SaleSection />
      <StatementsSection />
      <ReportingSection />
      <ManagementSection />
      <FreeTrialSection />
    </Box>
  );
}

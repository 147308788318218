import React, { useContext } from "react";
import { StyleContext } from "./contexts";
import { useTheme } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/system";

const StyledSelect = styled(Select)(({ theme, borderColor }) => ({
  '& .MuiSelect-icon': {
    color: borderColor, // Change the color of the arrow
  },
  '&:before': {
    borderBottomColor: borderColor, // Change the color of the underline
  },
}));


/**
 * Dropdown component.
 *
 * @component
 * @param {string} defaultValue - The default value of the dropdown.
 * @param {string} color - The color of the text in the dropdown. if "auto" is passed, the color will be calculated from the theme and the background, otherwise the color passed will be used. you can use null to use the default color.
 * @param {function} onChange - The function to be called when the dropdown value changes.
 * @param {ReactNode} children - The child elements of the dropdown.
 * @returns {JSX.Element} The rendered Dropdown component.
 */
export const Dropdown = ({ defaultValue, color = "auto", onChange, children }) => {
  const styleContext = useContext(StyleContext);
  const { backgroundColor } = styleContext;
  const theme = useTheme();
  color = color != "auto" ? color : styleContext.getContrastText(theme);
  
  return (
    <FormControl size="small" sx={{ color: color }} variant="standard">
      <StyledSelect
        defaultValue={defaultValue}
        variant="standard"
        sx={{ color: color }}
        onChange={onChange}
        borderColor={color}
      >
        {children}
      </StyledSelect>
    </FormControl>
  );
};


export const LanguageISO = {
    ENGLISH: "en",
    SPANISH: "es",
    ITALIAN: "it",
    FRENCH: "fr",
    GERMAN: "de",
    PORTUGUESE: "pt",
    DUTCH: "nl",
    POLISH: "pl",
    RUSSIAN: "ru",
    JAPANESE: "ja",
    CHINESE: "zh",
    KOREAN: "ko",
    ARABIC: "ar",
    HINDI: "hi",
    TURKISH: "tr",
    SWEDISH: "sv",
    DANISH: "da",
    NORWEGIAN: "no",
    FINNISH: "fi",
    GREEK: "el",
    HEBREW: "he",
    HUNGARIAN: "hu",
    INDONESIAN: "id",
    MALAY: "ms",
    THAI: "th",
    VIETNAMESE: "vi",
    CZECH: "cs",
    SLOVAK: "sk",
    UKRAINIAN: "uk",
    CROATIAN: "hr",
    SERBIAN: "sr",
    BULGARIAN: "bg",
    ROMANIAN: "ro",
    LITHUANIAN: "lt",
    LATVIAN: "lv",
    ESTONIAN: "et",
    SLOVENIAN: "sl",
}

class LocalizationHandler{

    constructor(){
        this.defaultLanguage = "en";
        this._language = localStorage.getItem("__language__") || this.defaultLanguage;
        this._languagesStrings = {};
    }

    get language(){
        return this._language || this.defaultLanguage;
    }

    set language(language){
        if(this._languagesStrings[language] !== undefined)
            this._language = language;
        else
            this._language = this.defaultLanguage;
        // store the language in local storage
        localStorage.setItem("__language__", this._language);
    }

    addLanguage(languageISO, languageStrings){
        this._languagesStrings[languageISO] = languageStrings;
    }

    get strings(){
        return this._languagesStrings[this.language];
    }

    get defaultStrings(){
        return this._languagesStrings[this.defaultLanguage];
    }

    get(key){
        let text = this.strings[key];
        if(text === undefined)
        text = this.defaultStrings[key];
        return text;
    }

}


export const Localization = new LocalizationHandler();

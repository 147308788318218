import React from "react";
import { ContentPanel, ContentPanelTitle, Row } from "../component_library/layout";
import { Image } from "../component_library/image";
import { Title } from "../component_library/title";
import { Localization } from "../component_library/localization";
import { Paragraph, ParagraphTitle, ParagraphBody, toHTML } from "../component_library/paragraph"
import { Box, useTheme } from "@mui/material";
import { FAQItem, MRTTitle, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTParagraphBody } from "../components/wrappers";
import { Stack } from "@mui/material";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();
  return (
    <ContentPanel color={theme.palette.background.paper} backgroundImageSrc={"images/page_title_background.png"}>
      <Row weights={[5, 7]} spacing={3}>
        <MRTTitle>{Localization.get("faq_page_title")}</MRTTitle>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1.1} animated={false} src="images/faq_page_title_image.png" />
          </Box>
        </Grow>
      </Row>
    </ContentPanel>
  );
}

function GeneralSection() {
  const theme = useTheme();
  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_potential_customers_general_question")
  ).length;

  return (
    <ContentPanel color={theme.palette.background.paper} >

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_potential_customers_general_title")}</ContentPanelTitle>

      <Stack direction={"column"} spacing={2}>
        {Array.from({ length: questionCount }, (_, i) => (
          <Paragraph>
            <FAQItem title={Localization.get(`faq_page_potential_customers_general_question${i + 1}`)}>
              <MRTParagraphBody>
                {toHTML(Localization.get(`faq_page_potential_customers_general_answer${i + 1}`))}
              </MRTParagraphBody>
            </FAQItem>
          </Paragraph>
        ))}
      </Stack>

    </ContentPanel>
  );
}

function FreeTrialSection() {
  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_potential_customers_free_trial_question")
  ).length;
  const theme = useTheme();

  return (
    <ContentPanel color={theme.palette.background.paper} >

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_potential_customers_free_trial_title")}</ContentPanelTitle>

      <Stack direction={"column"} spacing={2}>
        {Array.from({ length: questionCount }, (_, i) => (
          <Paragraph>
            <FAQItem title={Localization.get(`faq_page_potential_customers_free_trial_question${i + 1}`)}>
              <MRTParagraphBody>
                {toHTML(Localization.get(`faq_page_potential_customers_free_trial_answer${i + 1}`))}
              </MRTParagraphBody>
            </FAQItem>
          </Paragraph>
        ))}
      </Stack>
    </ContentPanel>
  );
}

function PurchasingSection() {
  const theme = useTheme();
  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_potential_customers_purchasing_question")
  ).length;

  return (
    <ContentPanel color={theme.palette.background.paper} >

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_potential_customers_purchasing_title")}</ContentPanelTitle>

      <Stack direction={"column"} spacing={2}>
        {Array.from({ length: questionCount }, (_, i) => (
          <Paragraph>
            <FAQItem title={Localization.get(`faq_page_potential_customers_purchasing_question${i + 1}`)}>
              <MRTParagraphBody>
                {toHTML(Localization.get(`faq_page_potential_customers_purchasing_answer${i + 1}`))}
              </MRTParagraphBody>
            </FAQItem>
          </Paragraph>
        ))}
      </Stack>

    </ContentPanel>
  );
}

function LicencesSection() {
  const questionCount = Object.keys(Localization.strings).filter(key =>
    key.includes("faq_page_potential_customers_licences_question")
  ).length;
  const theme = useTheme();

  return (
    <ContentPanel backgroundImageSrc="images/background_bottom.png" color={theme.palette.background.paper}>

      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>{Localization.get("faq_page_potential_customers_licences_title")}</ContentPanelTitle>

      <Stack direction={"column"} spacing={2}>
        {Array.from({ length: questionCount }, (_, i) => (
          <Paragraph>
            <FAQItem title={Localization.get(`faq_page_potential_customers_licences_question${i + 1}`)}>
              <MRTParagraphBody>
                {toHTML(Localization.get(`faq_page_potential_customers_licences_answer${i + 1}`))}
              </MRTParagraphBody>
            </FAQItem>
          </Paragraph>
        ))}
        <Row></Row>
      </Stack>

    </ContentPanel>
  );
}

export function FaqPagePotentialCustomers() {

  return (
    <Box>
      <TitleSection />
      <GeneralSection />
      <FreeTrialSection />
      <PurchasingSection />
      <LicencesSection />
    </Box>
  );

}
import { Card } from "@mui/material";
import { StyleContext, StyleProvider } from "../component_library/contexts";
import { useTheme } from "@emotion/react";
import React, { useState } from "react";
import { useMediaQuery } from "@mui/material";

export const MRTCardContainer = ({ color, ghost=true, children }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
    const defaultBackgroundColor = ghost? "transparent" : color; // Default background color
    const hoverBackgroundColor = color || theme.palette.background.paper; // Background color on hover
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (
        <StyleProvider value={{ backgroundColor: hoverBackgroundColor }}>
            <Card
                elevation={isHovered ? 20 : 0} // Increase elevation on hover
                sx={{
                    p: isSmallScreen ? "1rem" : "2rem",
                    my: "1rem",
                    borderRadius: "1rem",
                    width: "100%",
                    height: "80%",
                    backgroundColor: isHovered ? hoverBackgroundColor : defaultBackgroundColor,
                    transform: isHovered ? "scale(1.1)" : "scale(1)",
                    transition: "transform 0.2s ease-in-out, background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out", // Transition background color and box shadow
                    boxShadow: (isHovered || !ghost) ? `0 0 3rem rgba(116, 156, 230, 0.5)` : "none", // Light blue shadow on hover,
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    justifyItems: "center",
                    display: "flex",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {children}
            </Card>
        </StyleProvider>
    );
}

import React from "react";
import { Navbar, NavbarTitle, NavbarTitleIcon, NavbarItem, NavbarItemIcon, NavbarDivider, NavbarDrawerTitleIcon } from "../component_library/navigation";
import LanguageIcon from "@mui/icons-material/Language";
import { Dropdown } from "../component_library/inputs";
import MenuItem from "@mui/material/MenuItem";
import { Localization } from "../component_library/localization";
import { useTheme } from "@emotion/react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Text } from "../component_library/text";
import { MY_ROYAL_TREE_APP_LOGIN_URL } from "../App";


function LanguageDropdown() {
  const onChange = (event) => {
    Localization.language = event.target.value;
    //update the react page rendering
    window.location.reload();
  };
  return (
    <Dropdown defaultValue={Localization.language} onChange={onChange}>
      <MenuItem value="en">EN</MenuItem>
      <MenuItem value="it">IT</MenuItem>
    </Dropdown>
  );
}

function NavbarItemText({ children }) {
  return (
    <Text variant="body1" fontWeight="bold" center={false}>
      {children}
    </Text>
  );
}


export default function Header() {
  const theme = useTheme();
  const location =  useLocation();
  const isHome = window.location.pathname === "/" && (!window.location.hash || window.location.hash === "#/");

  const [update, setUpdate] = useState(0); // Aggiungi questo stato

  const [colors, setColors] = useState({
    navbarColor: isHome ? "transparent" : theme.palette.background.default,
    textColor: isHome ? theme.palette.common.white : theme.palette.text.primary,
    logoSrc : isHome ? "images/logo_white.png" : "images/logo.png"
  });

  useEffect(() => {
    const changeNavbarColor = () => {
      let isHome = window.location.pathname === "/" && (!window.location.hash || window.location.hash === "#/");
      if (window.scrollY >= 80) { // on scrolled
        setColors({
          navbarColor: theme.palette.background.default,
          textColor: theme.palette.text.primary,
          logoSrc: "images/logo.png",
          elevation: 5
        });
      } else if(isHome){ // on top home
        setColors({ 
          navbarColor: "transparent",
          textColor: theme.palette.common.white,
          logoSrc: "images/logo_white.png",
          elevation: 0
        });
      }else{ // on top not home
        setColors({ 
          navbarColor: theme.palette.background.default,
          textColor: theme.palette.text.primary,
          logoSrc: "images/logo.png",
          elevation: 0
        });
      }
      setUpdate(prevUpdate => prevUpdate + 1); // Incrementa 'update' per forzare un aggiornamento
    };

    changeNavbarColor();

    window.addEventListener('scroll', changeNavbarColor);

    return () => {
      window.removeEventListener('scroll', changeNavbarColor);
    };
  }, [theme, location]);


  return (
    <Navbar color={colors.navbarColor} textColor={colors.textColor} elevation={colors.elevation} fixed={true} fullWidth={true} height={"5rem"}>
      <NavbarTitle to="/">
        <NavbarTitleIcon>
          <img src={colors.logoSrc} alt="logo" width={96} height={56} />
        </NavbarTitleIcon>
        <NavbarDrawerTitleIcon>
          <img src={"images/logo.png"} alt="logo" width={96} height={56} />
        </NavbarDrawerTitleIcon>
      </NavbarTitle>
      <NavbarItem to="/features">
        <NavbarItemText>
          {Localization.get("header_features_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem to="/why-mrt">
        <NavbarItemText>
          {Localization.get("header_why-mrt_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem to="/pricing">
        <NavbarItemText>
          {Localization.get("header_pricing_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem to="/about">
        <NavbarItemText>
          {Localization.get("header_about_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem to="/contact">
        <NavbarItemText>
          {Localization.get("header_contact_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem>
        <NavbarItemText>
          {Localization.get("header_faq_button")}
        </NavbarItemText>
        <NavbarItem to={"/faq-user"}>
            {Localization.get("header_faq_users_button")}
        </NavbarItem>
        <NavbarItem to={"/faq-potential-customer"}>
            {Localization.get("header_faq_potential_customers_button")}
        </NavbarItem>
      </NavbarItem>
      <NavbarDivider />
      <NavbarItem to="/free-trial">
        <NavbarItemText>
          {Localization.get("header_free_trial_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem to={MY_ROYAL_TREE_APP_LOGIN_URL}>
        <NavbarItemText>
          {Localization.get("header_login_button")}
        </NavbarItemText>
      </NavbarItem>
      <NavbarItem>
        <NavbarItemIcon>
          <LanguageIcon />
        </NavbarItemIcon>
        <LanguageDropdown />
      </NavbarItem>
    </Navbar>
  );
}
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


let gaID = null;

export function setupGoogleAnalytics(googleAnalyticsID) {
    gaID = googleAnalyticsID;

    if (!window.gtag) {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
            window.dataLayer.push(arguments);
        };

        window.gtag('js', new Date());
        window.gtag('config', gaID, {
            send_page_view: false
        });
    }
}



const GoogleAnalyticsTrackPage = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: location.pathname,
    });
  }, [location]);
  
  return null;
};


export const withGoogleAnalytics = (Component) => {
        return (
            <>
                <Component/>
                <GoogleAnalyticsTrackPage/>
            </>
        );
};


import * as React from "react";
import {
  ContentPanel,
  ContentPanelTitle,
  Row,
  Col,
} from "../component_library/layout";
import { useTheme, Box, Stack, Container } from "@mui/material";
import { Title } from "../component_library/title";
import {
  Paragraph,
  ParagraphBody,
  ParagraphTitle,
  toHTML,
} from "../component_library/paragraph";
import { ButtonWrapper, MRTTitle, MRTParagraphTitle, MRTParagraphBody, CONTENT_PANEL_TITLE_FONT_WEIGHT, MRTImage } from "../components/wrappers";
import { Localization } from "../component_library/localization";
import { FreeTrialSection } from "../components/free_trial_section";
import { Image } from "../component_library/image";
import { ImageTextBlock } from "../components/image_text_block";
import { MRTCardContainer } from "../components/foton_card";
import { useMediaQuery } from "@mui/material";
import { Grow } from "@mui/material";

function TitleSection() {
  const theme = useTheme();
  const white = "#FFFFFF";
  const backgroundImageSrc = "images/banner_background.png";
  const backgroundColor = theme.palette.common.transparent;

  return (
    <Container maxWidth={true} sx={{
      backgroundColor: `${backgroundColor}`, backgroundImage: `url(${backgroundImageSrc})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      margin: 0,
      py: "5rem",
      pl: { xs: "1rem", md: "5rem", lg: "10rem", xl: "11rem" },
      pr: { xs: "1rem", sm: "2rem", md: "0rem", lg: "0rem", xl: "0rem" },
    }}>
      <Row weights={[5, 7]} spacing={0}>
        <Col spacing={3} weights={[7, 2, 3]} center={false}>
          <MRTTitle color={white} variant="h1" center={false}>{Localization.get("homepage_title")}</MRTTitle>
          <MRTTitle color={white} variant="h4" center={false}>{Localization.get("homepage_subtitle")}</MRTTitle>
          <Box>
            <ButtonWrapper color="secondary" to="/free-trial">
              {Localization.get("homepage_free_trial_button")}
            </ButtonWrapper>
          </Box>
        </Col>
        <Grow in={true} timeout={1000}>
          <Box>
            <Image scale={1} animated={false} src="images/homepage_title_section_image.png" />
          </Box>
        </Grow>
      </Row>
    </Container>
  );
}

function DiscountSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ContentPanel padding="0rem" color={theme.palette.background.paper}>
      <MRTCardContainer color={theme.palette.background.paper}>
        <Title variant={isSmallScreen? "h4" : "h3"} fontWeight="bold">{Localization.get("homepage_discount")}</Title>
      </MRTCardContainer>
    </ContentPanel>
  );
}

function IntroSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"} padding={"8rem"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("homepage_intro_title")}
      </ContentPanelTitle>

      <Row>
        <Paragraph>
          <MRTParagraphBody center>
            {Localization.get("homepage_intro_body")}
          </MRTParagraphBody>
        </Paragraph>
      </Row>

      <Row>
        <ImageTextBlock orientation={"vertical"} image={"images/homepage_intro_section_image_1.png"} title={Localization.get("homepage_intro_affordable_title")} body={Localization.get("homepage_intro_affordable_body")} />
        <ImageTextBlock orientation={"vertical"} image={"images/homepage_intro_section_image_2.png"} title={Localization.get("homepage_intro_effortless_title")} body={Localization.get("homepage_intro_effortless_body")} />
        <ImageTextBlock orientation={"vertical"} image={"images/homepage_intro_section_image_3.png"} title={Localization.get("homepage_intro_customisable_title")} body={Localization.get("homepage_intro_customisable_body")} />
      </Row>

      <Row>
        <ButtonWrapper color="secondary" to="/why-mrt">
          {Localization.get("homepage_summary_info_button")}
        </ButtonWrapper>
      </Row>
    </ContentPanel>
  );
}

function Section2() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"} padding={"3rem"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("homepage_section2_title")}
      </ContentPanelTitle>
      <Row>
        <Paragraph>
          <MRTParagraphBody>
            {toHTML(Localization.get("homepage_section2_paragraph1_body"))}
          </MRTParagraphBody>
        </Paragraph>
      </Row>
    </ContentPanel>
  );
}

function SummarySection() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"} padding={"3rem"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("homepage_summary_title")}
      </ContentPanelTitle>
      <Row>
        <Paragraph>
          <MRTParagraphBody>
            {toHTML(Localization.get("homepage_summary_body"))}
          </MRTParagraphBody>
        </Paragraph>
      </Row>
      <Row>
        <Image src="images/process_image.png" />
      </Row>
      <Row>
        <ButtonWrapper color="primary" to="/why-mrt">
          {Localization.get("homepage_summary_info_button")}
        </ButtonWrapper>
      </Row>
    </ContentPanel>
  );
}

function FeaturesSection() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ContentPanel color={"#00000000"} padding={"4rem"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        <Box sx={{ mb: "4rem" }}>
          {Localization.get("homepage_features_title")}
        </Box>
      </ContentPanelTitle>

      <Row weights={[5, 7]} sx={{ mb: "4rem" }}>
        <ImageTextBlock orientation={"vertical"} center={false} image={"images/homepage_features_section_image_1.png"} title={Localization.get("homepage_features_reminders_title")} body={Localization.get("homepage_features_reminders_body")} />
        <MRTImage src="images/automatic_reminders_screen.png" />
      </Row>

      {isSmallScreen ?
        <Row sx={{ mb: "4rem" }}>
          <ImageTextBlock orientation={"vertical"} center={false} image={"images/homepage_features_section_image_2.png"} title={Localization.get("homepage_features_rights_title")} body={Localization.get("homepage_features_rights_body")} />
          <MRTImage src="images/rights_tree_screen.png" />
        </Row> :
        <Row weights={[7, 5]} sx={{ mb: "4rem" }} >
          <MRTImage src="images/rights_tree_screen.png" />
          <ImageTextBlock orientation={"vertical"} center={false} image={"images/homepage_features_section_image_2.png"} title={Localization.get("homepage_features_rights_title")} body={Localization.get("homepage_features_rights_body")} />
        </Row>}

      <Row weights={[5, 7]} sx={{ mb: "4rem" }}>
        <ImageTextBlock orientation={"vertical"} center={false} image={"images/homepage_features_section_image_3.png"} title={Localization.get("homepage_features_private_area_title")} body={Localization.get("homepage_features_private_area_body")} />
        <MRTImage src="images/private_author_area_screen.png" />
      </Row>
      <Row>
        <ButtonWrapper color="primary" to="/features">
          {Localization.get("homepage_features_button")}
        </ButtonWrapper>
      </Row>
    </ContentPanel>
  );
}

export const PricingCard = ({ color, children }) => {

  return (
    <MRTCardContainer color={color}>
      <Col spacing={2} weights={[4, 6, 2]}>
        {children}
      </Col>
    </MRTCardContainer>
  );

};

function PricingSection() {
  const theme = useTheme();

  return (
    <ContentPanel color={"#00000000"} padding={"3rem"}>
      <ContentPanelTitle fontWeight={CONTENT_PANEL_TITLE_FONT_WEIGHT}>
        {Localization.get("homepage_pricing_title")}
      </ContentPanelTitle>
      <Row>
        <PricingCard color={theme.palette.background.default}>
          <Image src="images/homepage_pricing_section_image_1.png" />
          <Paragraph>
            <MRTParagraphTitle center>{Localization.get("homepage_pricing_author_title")}</MRTParagraphTitle>
            <MRTParagraphBody center>{Localization.get("homepage_pricing_author_body")}</MRTParagraphBody>
          </Paragraph>
          <ButtonWrapper color="primary" to="/pricing/#author">
            {Localization.get("homepage_pricing_author_button")}
          </ButtonWrapper>
        </PricingCard>
        <PricingCard color={theme.palette.background.default}>
          <Image src="images/homepage_pricing_section_image_2.png" />
          <Paragraph>
            <MRTParagraphTitle center>{Localization.get("homepage_pricing_agent_title")}</MRTParagraphTitle>
          </Paragraph>
          <ButtonWrapper color="primary" to="/pricing/#agent">
            {Localization.get("homepage_pricing_agent_button")}
          </ButtonWrapper>
        </PricingCard>
        <PricingCard color={theme.palette.background.default}>
          <Image src="images/homepage_pricing_section_image_3.png" />
          <Paragraph>
            <MRTParagraphTitle center>{Localization.get("homepage_pricing_publisher_title")}</MRTParagraphTitle>
          </Paragraph>
          <ButtonWrapper color="primary" to="/pricing/#publisher">
            {Localization.get("homepage_pricing_publisher_button")}
          </ButtonWrapper>
        </PricingCard>
      </Row>
    </ContentPanel>
  );
}

export function HomePage() {
  return (
    <Box sx={{ backgroundImage: "url(images/background.png)" }}>
      <TitleSection />
      <DiscountSection />
      <IntroSection />
      <FeaturesSection />
      <PricingSection />
      <FreeTrialSection />
    </Box>
  );
}
